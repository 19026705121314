import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

const PromoSlide = ({ children, title }) => {
	const settings = {
		dots: true,
		centerMode: true,
		infinite: true,
		variableWidth: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	}

	return (
		<div className={`apl-promoslide`}>
			<div className='container'>
				<h2>{title}</h2>
				<Slider {...settings}>{children}</Slider>
			</div>
		</div>
	)
}

PromoSlide.displayName = 'PromoSlide'

PromoSlide.propTypes = {
	children: PropTypes.any,
	title: PropTypes.string,
}

PromoSlide.defaultProps = {
	children: null,
	title: null,
}

export default PromoSlide
