import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const MediaItem = ({ imageSrc, imageAlt, link }) => {
	return (
		<div className='apl-promoslide--item'>
			<a href={link}>
				<GatsbyImage image={imageSrc} alt={imageAlt} />
			</a>
		</div>
	)
}

MediaItem.displayName = 'MediaItem'

MediaItem.propTypes = {
	imageSrc: PropTypes.object,
	imageAlt: PropTypes.string,
	link: PropTypes.string,
}

MediaItem.defaultProps = {
	imageSrc: null,
	imageAlt: null,
	link: null,
}

export default MediaItem
